.content-topic-list {
  height: calc(100vh - 156px);
  overflow: auto;
  padding-left: 25px;
  padding-right: 35px;

  // &__list{
  //     list-style: none;
  // }

  &__actions {
    display: flex;
    color: #fff;
  }

  &__save-btn {
    background-color: #246bfd;
    padding: 0.4rem 4.5rem;
    margin-right: 10px;
    border-radius: 8px;
    color: #fff;
  }

  &__save-draft {
    background-color: transparent;
    width: 40px;
    height: 40px;
    border: 1px solid #246bfd;
    border-radius: 8px;
    color: #fff;

    &--disabled {
      cursor: not-allowed !important;
    }
  }

  &__title {
    color: #fff;
  }
}

@media (min-width: 768px) and (max-width: 830px) {
  .content-topic-list {
    padding-left: 0;
    padding-right: 0;
  }
}
