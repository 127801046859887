@import './../styles/variables';

.acc-loading-indicator {
  margin-left: 28px;
  margin-top: 50px;
}

.edit-profile-span {
  color: map-get($colors, pure);
  background-color: map-get($colors, secondary-blue);
  padding: 4px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: none;
}

.edit-profile-span-disable {
  color: map-get($colors, grey);
  cursor: default;
}

.save-profile-span {
  color: map-get($colors, pure);
  background-color: map-get($colors, secondary-blue);
  padding: 4px 20px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: none;
}

.save-profile-span-disable {
  color: map-get($colors, grey);
  cursor: default;
}

.cancel-profile-span {
  color: map-get($colors, pure);
  padding: 4px 20px;
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: none;
  border: 1px solid #246BFD;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1;
}



.edit-bio-span {
  color: map-get($colors, secondary-blue);
  font-size: 14px;
  cursor: pointer;
  line-height: 1;
  transition: none;
}

.edit-bio-span-disable {
  color: map-get($colors, grey);
  cursor: default;
}

.change-email-span {
  color: map-get($colors, pure);
  background-color: map-get($colors, secondary-blue);
  padding: 4px 20px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  line-height: 1;
  transition: none;
  margin-right: 5px;
}

.change-email-span-disable {
  cursor: not-allowed;
  background-color: map-get($colors, grey);
}

.cancel-email-span {
  color: map-get($colors, pure);
  border: 1px solid #246BFD;
  padding: 4px 20px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  line-height: 1;
  transition: none;
  margin-right: 5px;
}

.cancel-email-span-disable {
  cursor: not-allowed;
  background-color: map-get($colors, grey);
}

.change-phone-span {
  color: map-get($colors, pure);
  background-color: map-get($colors, secondary-blue);
  padding: 4px 20px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  line-height: 1;
  transition: none;
  margin-right: 5px;
}

.change-phone-span-disable {
  cursor: not-allowed;
  background-color: map-get($colors, grey);
}

.cancel-phone-span {
  color: map-get($colors, pure);
  border: 1px solid #246BFD;
  padding: 4px 20px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  line-height: 1;
  transition: none;
  margin-right: 5px;
}

.cancel-phone-span-disable {
  cursor: not-allowed;
  background-color: map-get($colors, grey);
}

.card-section {
  margin-top: 20px;
  border: 1px solid #e6e6e6;
  padding: 20px;
  display: flex;

  span {
    color: map-get($colors, grey);
  }

  .form-card {
    width: 200px;
    display: inline-block;
    margin-left: 20px;
  }

  .btn-add-card {
    color: map-get($colors, secondary-blue);
    font-size: 14px;
    background-color: transparent;
    outline: none;
    border: none;
    width: 200px;
    margin-left: 20px;
    display: inline-block;
  }
}

.account-margin {
  margin-top: 16px;
}

.grade-margin {
  margin-top: 16px;
  margin-right: 16px;
}

.bio-margin{
  margin-top: 25px;
  padding-right: 16px;
}

.ab-l-col {
  padding-left: 45px !important;
  padding-right: 20px !important;
  transition: none;
}

.ab-r-col {
  padding-left: 0 !important;
  transition: none;
}

.acc-e-margin {
  margin-top: 58px;
}


@media (max-width: 1050px) {
  .ab-r-col {
    padding-left: 0px !important;
    padding-right: 10px !important;
    width: 100% !important;
  }

  .ab-l-col {
    padding-left: 0px !important;
    padding-right: 10px !important;
    width: 100% !important;
  }
}



@media (max-width: 991.98px) {
  .ab-r-col {
    padding-left: 0px !important;
    padding-right: 10px !important;
    width: 100% !important;
  }

  .ab-l-col {
    padding-left: 0px !important;
    padding-right: 10px !important;
    width: 100% !important;
  }
  // .ab-l-col::-webkit-scrollbar {
  //   display: none;
  // }
}

@media (max-width: 767.98px) {
  .acc-loading-indicator {
    margin-left: 0;
  }

  .ab-l-col {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .ab-r-col {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media (max-width: 575.98px) {
  .account-margin {
    margin-top: 10px;
  }
}

.account-border {
  border: 2px solid #1F2130;
  padding: 20px;
  border-radius: 5px;
  height: calc(100vh - 135px);
  position: relative;
  width: 80vh;
}

@media (max-width: 1425px) {
  .account-border {
    width: 100%;
  }
}

.account-detail-titles {
  font-size: 14px;
  color: #73847f;
  line-height: 18.75px;
}

.account-detail-data {
  font-size: 16px;
  margin-top: -2px;
  color: #ffffff;
  margin-bottom: 25px;
  line-height: 23.44px;
}

.account-detail-bio {
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  text-align: justify;
}

.account-detail-edit {
  margin-top: 20px;
  position: absolute;
  bottom: 5;
  width: 100%;
}

.account-l-scrollView {
  height: calc(100vh - 220px);
  overflow-y: auto;
}

.account-rSide-box {
  background: #1f2130;
  box-shadow: 0px 0px 8px #181924;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;

  &__title{
    color: #73847f;
    font-size: 14px;
  }

  &__content{
    color: #ffffff;
    margin-right: 10px;
    font-size: 16px;
  }

  &__edit{
    float: right;
    margin: auto;
  }
}
