.content-main-preview-snav {


    &__header {
        display: flex;
        padding-left: 40px;
        padding-top: 10px;
        color: #fff;
        align-items: center;

        h6 {
            padding-left: 10px;
            margin: 0;
        }
    }

    &__items {
        margin-top: 20px;
        overflow: auto;
        height: calc(100vh - 160px);
        @media (max-width: 767.98px) {
            height: calc(100vh - 215px);
        }
    }

    &__item {
        color: #fff;
        text-align: left;
        cursor: pointer;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 30px;
        margin: 0;


        &--active {
            background-color: #246bfd;
        }
    }

    &__text-content{
        display:flex;
        align-items: center;
        justify-content: space-between;
        padding-right:15px;

       &__icon{
        &--reveal{
            transform: rotate(90deg);
        }
       }
    }

    &__sublist {
        background-color: transparent;
        text-align: left;
        padding-left: 20px;
        transition: opacity 0.2s ease-out !important;
        opacity: 0;
        height: 0;
        overflow: hidden;

        &--show{
            opacity: 1;
            height: auto;
          
        }

        &-sub-item{
            cursor: pointer;
            &--active{
                color: #246bfd;
               
            }
        }

        &-item {
            color: #979797;
            font-size: 14px;
            background-color: transparent;
            padding: 5px;
            padding-left: 10px;
            list-style: none;
            display:flex;
            align-items: center;
           
            

            &--active {
                color: #246bfd;
                transition: 0.2s ease-out !important;
            }

            &:first-child {
                margin-top: 15px;
            }
        }

    }
}

// @media (max-width: 767.98px) {
//     .acc-loading-indicator {
//       margin-left: 0;
//     }
  
//     .ab-l-col {
//       padding-left: 15px !important;
//       padding-right: 15px !important;
//     }
  
//     .ab-r-col {
//       padding-left: 15px !important;
//       padding-right: 15px !important;
//     }
//   }
  