.content-main-preview {
    background-color: #fff;
    padding-top: 50px;
    border-radius: 0;
    height: calc(100vh - 135px);
    overflow: auto;
    padding-bottom: 1.3rem;



    & *{
        transition: none !important;
    }

    & p{
        color: #2E3D49;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 141.69%;
    }

    &__m-0{
        margin-left: -15px !important;
        margin-right: -15px !important;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        // background: #181924;
        // padding-bottom: 1.2rem;
        // margin-top:-3.125rem;
        // padding-right:14px;
    }

    &__filename{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 109.69%;
        color: #2E3D49;
    }

    &-title{
        &__tertiary{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 125%;
            color: #1F2130;
            margin-top:2rem
        }
    }

    &__links{
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color:#246BFD;
    margin-top: 2rem

    }

    &__main{
       min-height: 90%;


        &>div{
        background: #181924;
        padding-bottom: 1.2rem;
        padding-top: 1.2rem;
        padding-right:14px;
        margin-top: 2rem;
        }

       &>div:first-child{
        background: #181924;
        padding-bottom: 1.2rem;
        margin-top:-3.125rem !important;
        padding-right:14px;
       }  

       &>*{
        margin-left:12vw;
        margin-right:12vw;
       }
    }

    &__next-btn{
        width: 180px;
        height: 40px;
        padding:5px 10px;
        background-color: #246bfd;
        border-radius: 8px;
        color:#fff;
        margin-bottom: 10px;
        margin-left:auto;
        margin-right: 1rem;   

        &--disabled{
            background-color: grey;
            cursor: not-allowed;
        }

    }
}



@media only screen and (max-width: 768px) {
    .content-main-preview__next-btn{
        width:90vw !important;
    }

    .content-main-preview__main{
         &>div{
         padding-bottom: 0;
         padding-top: 0;
         padding-right:0;
         margin-top: 2rem;
         }
 
        &>div:first-child{
         background: #181924;
         padding-bottom: 0;
         margin-top:-3.125rem !important;
         padding-right:0;
        }  

    }
    
}

@media (min-width:768px) and (max-width:940px) {
    
    .content-main-preview{
        &__main>*{
            margin-left:2vw;
            margin-right:2vw;
        }
        &__main{
            &>div{
                padding-bottom: 0;
                padding-top: 0;
                padding-right:0;
                margin-top: 2rem;
                }
        
               &>div:first-child{
                background: #181924;
                padding-bottom: 0;
                margin-top:-3.125rem !important;
                padding-right:0;
               }  
        }
    }

    .content-main-preview__m-0{
        margin-left:0 !important;
        margin-right:0 !important;
    }
    
}

@media (min-width:831px) and (max-width:1100px) {
.content-main-preview{
    & p{
        font-size: 0.875rem;
    }
}
}


.content-text>p:has(strong){
    margin-bottom: 0rem;
    margin-top: 2rem;
}

.plyr{
    width:100%
}