@import './../styles/variables';

.add-students-modal {
  inset: 50% auto auto 50%;
  margin-right: -50%;
  transform: translate(-50%, 0);
  position: absolute;
  border-radius: 15px;
  outline: none;
  padding: 30px 35px 40px 35px;
  background-color: map-get($colors, primary-blue-light); 
  top: 10vh;
  transition: 0.3s;
  width: 45%;
  height: auto;
  color: map-get($colors, pure);
  font-family: 'Roboto';
  font-style: normal;

  .custom-selected-students-list{
    height: calc(100vh - 450px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .add-new-students-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
  }

  .add-new-students-modal_user-role-input-field {
    margin-top: 8%;
    margin-bottom: 6%;
  }

 

  .add-new-students-modal-confirm-button {
    display: flex;
    column-gap: 10px;
    align-items: flex-start;
  }

  .add-new-students-modal-button-disabled {
    background-color: map-get($colors, secondary-grey);
  }

  .selected-students-card{
    border-radius: 4px;
    background: #1F2130;
    box-shadow: 0px 0px 8.89855px 0px #181924;


    .selected-student-name{
        color: #FFF;
        font-family: Roboto;
        font-size: 15.572px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .selected-student-email{
        color: #979797;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
  }
}
