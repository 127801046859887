@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.content2 {
  background: #1f2130;
  grid-area: content2;
  border-radius: 8px;
  box-shadow: 0px 0px 8px #181924;
  position: relative;
  width: 418px;
  height: fit-content;

  margin-left: auto;

  &__image {
    object-fit: cover;
    background-color: #c4c4c4;
    width: 171px;
    height: 160px;
    border-radius: 8px;
    margin: 1rem;
  }

  &__info {
    margin-top: 6%;
    position: absolute;
    top: 0%;
    left: 50%;
  }

  &__pricetag {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    transform: translateX(10%);
  }

  &__original-price-tag {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    color: #f05a5a;
  }

  &__set-margine-orinal-price-tag {
    margin-bottom: 0.5rem;
  }

  &__text {
    font-size: 12px;
    font-weight: 400;
    color: #a4a4a4;
  }

  &__btn-view {
    font-family: 'Roboto', sans-serif;
    background: #246bfd;
    border-radius: 6.90085px;
    width: 185px;
    height: 50px;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    margin-bottom: 0.5rem;
  }

  &__btn-codes {
    font-family: 'Roboto', sans-serif;
    width: 112px;
    height: 30px;
    background: #404462;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    cursor: not-allowed !important;
  }

  &__btn-stdcodes {
    font-family: 'Roboto', sans-serif;
    width: 180px;
    height: 16px;
    background: #1f2130;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3a7bff;
  }

  &__accesscode-applied-stdcodes-text {
    display: flex;
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #979797;
    margin-bottom: 0.3rem;
  }

  &__btn-pop {
    width: 30px;
    height: 30px;
    background: #404462;
    border-radius: 4px;
    color: #fff;
    margin-left: 0.3rem;
  }

  &__btn-del {
    width: 30px;
    height: 30px;
    background: #f05a5a;
    border-radius: 4px;
    color: #fff;
    margin-left: 0.3rem;
  }

  &__icon-1 {
    position: relative;
  }

  &__icon-1 svg {
    position: absolute;
    left: 14%;
    top: 13%;
  }

  &__icon-2 svg {
    position: absolute;
    left: 60%;
  }

  &__access-code-container {
    background: #181924;
    height: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__access-code-input {
    margin-left: 1.5rem;
    width: 70%;
  }

  &__access-code-apply-button {
    width: 20%;
  }

  &__access-code-apply-button-customize {
    height: 30px;
    border-radius: 6.98px;
  }

  &__applied-access-code-box {
    position: absolute;
    background: #181924;
    border-radius: 4px;
    width: 185px;
    height: 23px;
    margin-top: 0.1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  &__applied-access-code-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #979797;
    align-self: center;
  }

  &__applied-access-code-discount {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #20d167;
    align-self: center;
  }
}

.svg__text {
  &-1 {
    display: inline;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #a4a4a4;
    padding-left: 3rem;
  }

  &-2 {
    display: inline;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #a4a4a4;
    padding-left: 4rem;
  }
}

.content2-bottom-size {
  flex-direction: row;
  align-items: center;
  height: 3rem;
}
.flex-container {
  display: flex;
}

.flex-child {
  flex: 1 1 content;
}

.flex-child-2 {
  flex: auto;
  flex-grow: 0;
}

@media only screen and (max-width: 767.98px) {
  .content2 {
    margin-left: 3%;
    min-width: 86vw;

    &__info {
      margin-top: 6%;
    }
  }

  .svg__text {
    &-1 {
      padding-left: 4.8rem;
    }

    &-2 {
      padding-left: 6.2rem;
    }
  }
}

@media only screen and (max-width: 590px) {
  .svg__text {
    &-1 {
      padding-left: 3rem;
    }

    &-2 {
      padding-left: 4.2rem;
    }
  }
}

@media only screen and (max-width: 1500px) {
  .content2 {
    margin-left: 3%;
  }
}
